* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/*------------Globa----------*/
.container {
  max-width: 80%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style: none;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

footer {
  background-color: #08071c;
  padding: 100px 0 0 0;
  color: #fff;
}
footer p {
  color: #fff;
  margin: 20px 0;
}

footer h2 {
  margin-bottom: 30px;
  font-size: 20px;
}
footer li {
  color: #fff;
  margin-bottom: 15px;
}
footer li:before {
  content: "·";
  font-size: 40px;
  vertical-align: middle;
  line-height: 10px;
  margin-right: 20px;
  color: #38d16a;
}
footer .box p {
  margin: 0;
  margin-top: 15px;
}
footer span {
  color: #38d16a;
  opacity: 1;
}
footer .icon {
  margin-top: 20px;
  display: flex;
}

.legal {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 50px;
  padding: 20px 0;
}

@media screen and (max-width: 768px) {
  footer .grid1 {
    grid-template-columns: repeat(1, 1fr);
  }
}